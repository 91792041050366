



































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
    apiAdminAdd,
    apiAdminDetail,
    apiAdminEdit,
    apiRoleList,
    apiAdminOtheradd,
    apiAdminOtheredit,
    apiSupplierlists,
    apiShoplists,
} from "@/api/setting/permissions";
import { AdminAdd_Req, AdminEdit_Req } from "@/api/setting/permissions.d";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
    components: {
        MaterialSelect,
    },
})
export default class AdminEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD; // 当前页面【add: 添加管理员 | edit: 编辑管理员】
    identity: number | null = null; // 当前编辑用户的身份ID  valid: mode = 'edit'
    roleList: Array<object> = []; // 角色的数据
    api_type: any = "platform";
    // 添加管理员表单数据
    form: any = {
        account: "", // 账号
        name: "", // 名称
        phone: "", // 手机号
        password: "", // 密码
        password_confirm: "", // 确认密码
        role_id: "", // 角色id
        disable: 0, // 禁用：0-否；1-是
        multipoint_login: 1, // N端登录：0-否；1-是
        avatar: "", // 头像路径
    };

    // 校验密码
    validatePassword = [
        { required: true, message: "请输入密码", trigger: "blur" },
        {
            validator: (rule: object, value: string, callback: any) => {
                !value ? callback(new Error("请输入密码")) : callback();
            },
            trigger: "blur",
        },
    ];

    // 校验确认密码
    validatePasswordConfirm = [
        { required: true, message: "请再次输入密码", trigger: "blur" },
        {
            validator: (rule: object, value: string, callback: any) => {
                if (this.form.password) {
                    if (!value) {
                        callback(new Error("请再次输入密码"));
                    }
                    if (value !== this.form.password) {
                        callback(new Error("两次输入密码不一致!"));
                    }
                }
                callback();
            },
            trigger: "blur",
        },
    ];

    // 表单校验
    rules = {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        role_id: [{ required: true, message: "请选择角色", trigger: "change" }],
        //校检手机号码格式
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" },
        { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" }],
        password: [],
        password_confirm: [],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return;
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleAdminAdd();
                case PageMode.EDIT:
                    return this.handleAdminEdit();
            }
        });
    }

    // 添加管理员
    handleAdminAdd() {
        const form = this.form as AdminAdd_Req;
        if (this.api_type === "shop") {
            apiAdminOtheradd({ ...form, sid: localStorage.getItem("shopId") })
                .then(() => {
                    this.$message.success("添加成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
        if (this.api_type === "supplier") {
            apiAdminOtheradd({
                ...form,
                sid: localStorage.getItem("supplierId"),
            })
                .then(() => {
                    this.$message.success("添加成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
        if (this.api_type === "platform") {
            apiAdminAdd(form)
                .then(() => {
                    this.$message.success("添加成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
    }

    // 编辑管理员
    handleAdminEdit() {
        const params = this.form;
        const id: number = this.identity as number;

        if (this.api_type === "shop") {
            apiAdminOtheredit({
                ...params,
                id,
                sid: localStorage.getItem("shopId"),
            })
                .then(() => {
                    this.$message.success("修改成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
        if (this.api_type === "supplier") {
            apiAdminOtheredit({
                ...params,
                id,
                sid: localStorage.getItem("supplierId"),
            })
                .then(() => {
                    this.$message.success("修改成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
        if (this.api_type === "platform") {
            apiAdminEdit({ ...params, id } as AdminEdit_Req)
                .then(() => {
                    this.$message.success("修改成功!");
                    setTimeout(() => this.$router.go(-1), 500);
                })
                .catch(() => {
                    this.$message.error("保存失败!");
                });
        }
    }

    // 获取角色列表
    geRoleList() {
        if (this.api_type === "shop") {
            apiShoplists({
                page_type: 1,
                sid: localStorage.getItem("shopId"),
            }).then((res) => {
                this.roleList = res.lists;
            });
        }
        if (this.api_type === "supplier") {
            apiSupplierlists({
                page_type: 1,
                sid: localStorage.getItem("supplierId"),
            }).then((res) => {
                this.roleList = res.lists;
            });
        }
        if (this.api_type === "platform") {
            apiRoleList({
                page_type: 1,
            }).then((res) => {
                this.roleList = res.lists;
            });
        }
    }

    // 表单初始化数据 [编辑模式] mode => edit
    initFormDataForAdminEdit() {
        apiAdminDetail({
            id: this.identity as number,
        })
            .then((res) => {
                Object.keys(res).map((key) => {
                    this.$set(this.form, key, res[key]);
                });
            })
            .catch(() => {
                this.$message.error("数据初始化失败，请刷新重载！");
            });
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.api_type = localStorage.getItem("api_type");
        if (this.$route.path == "/setting/permissions/admin") {
            this.api_type = "platform";
            localStorage.setItem("api_type", "platform");
            localStorage.removeItem("supplierId");
            localStorage.removeItem("shopId");
        }
        const query: any = this.$route.query;

        if (query.mode) {
            this.mode = query.mode;
        }

        // 编辑模式：初始化数据
        if (this.mode === PageMode.EDIT) {
            this.identity = query.id * 1;
            this.initFormDataForAdminEdit();
        }

        if (this.mode === PageMode.ADD) {
            this.$set(this.rules, "password", this.validatePassword);
            this.$set(
                this.rules,
                "password_confirm",
                this.validatePasswordConfirm
            );
        }

        // 获取角色列表
        this.geRoleList();
    }

    @Watch("form.password")
    changePasswordInput(value: string) {
        if (this.mode === PageMode.EDIT) {
            value
                ? this.$set(
                    this.rules,
                    "password_confirm",
                    this.validatePasswordConfirm
                )
                : this.$set(this.rules, "password_confirm", []);
        }
    }

    /** E Life Cycle **/
}
